import { render, staticRenderFns } from "./CheckBox.vue?vue&type=template&id=1bf8431e&scoped=true&"
import script from "./CheckBox.vue?vue&type=script&lang=js&"
export * from "./CheckBox.vue?vue&type=script&lang=js&"
import style0 from "./CheckBox.vue?vue&type=style&index=0&id=1bf8431e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../.yarn/__virtual__/vue-loader-virtual-78585250f4/0/cache/vue-loader-npm-15.10.0-778eb54c35-1bf6c9ffea.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bf8431e",
  null
  
)

export default component.exports